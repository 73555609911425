@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  /* Custom font */
  @font-face {
    font-display: swap;
    font-family: 'Bricolage Grotesque';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/bricolage-grotesque-v7-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  @font-face {
    font-display: swap;
    font-family: 'Bricolage Grotesque';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/bricolage-grotesque-v7-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
}